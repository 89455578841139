<template>
  <div>
    <b-row>
      <b-col cols="12" class="">
        <Breadcrumbs :title="$route.name" class="mt-3" />
      </b-col>

      <b-col cols="12">
        <form-card />
      </b-col>

      <b-col cols="12" md="6">
        <div class="text-start bg-white shadow shadow-lg rounded mt-3">
          <chart />
        </div>
      </b-col>
      <b-col cols="12" md="6"><percentage /></b-col>
    </b-row>
    <div>
      <b-tabs content-class="mt-3">
        <b-tab title="Non Gazzeted ACR List" active>
          <non-gazzeted-acr-list />
        </b-tab>
        <b-tab v-if="loggedInUser.grade < 11" title="Gazzeted Officers ACR List">
          <gazzeted-acr-list />
        </b-tab>
        <!-- <b-tab title="Gazzeted Cadre Officers ACR List"
          ><p>I'm a disabled tab!</p></b-tab
        > -->
      </b-tabs>
    </div>
  </div>
</template>

<script>
import formCard from "./default/formCard.vue";
import chart from "./default/chart.vue";
import percentage from "./default/percentage.vue";
import nonGazzetedAcrList from "./components/nonGazzetedAcrTable.vue";
import gazzetedAcrList from "@/pages/acr/gazzeted/list.vue";
import { getLogedInUserInfo } from "@/utils/auth";
export default {
  components: {
    formCard,
    chart,
    percentage,
    nonGazzetedAcrList,
    gazzetedAcrList,
  },
  data() {
    return {
      loggedInUser: JSON.parse(getLogedInUserInfo()),
    };
  },
};
</script>
