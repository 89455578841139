<template>
  <div class="border rounded bg-white p-3">
    <iro-table :list="iroList" />
    <cro-table :list="croList" />
    <dossier-table :list="dossierList" />
    <user-table :list="userList" />
  </div>
</template>

<script>
import iroTable from "./components/iro-table.vue";
import croTable from "./components/cro-table.vue";
import dossierTable from "./components/dossier-table.vue";
import userTable from "./components/user-table.vue";

import { getLogedInUser } from "@/utils/auth";
export default {
  components: {
    iroTable,
    croTable,
    dossierTable,
    userTable,
  },
  data() {
    return {
      list: [],
      iroList: [],
      croList: [],
      dossierList: [],
      userList: [],
    };
  },
  mounted() {
    this.viewAllGazzetedAcr();
  },
  methods: {
    viewAllGazzetedAcr() {
      this.$store
        .dispatch("acrStore/viewAllGazzetedAcr")
        .then((response) => {
          this.list = response.data;
          this.iroList = this.list.filter((item) => item.status === "iro");
          this.croList = this.list.filter((item) => item.status === "cro");
          this.dossierList = this.list.filter((item) => item.status === "done");
          this.userList = this.list.filter((item) => item.userIdNo === JSON.parse(getLogedInUser()).user_id);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
