<template>
  <div class="px-2" id="form-card">
    <b-row>
      <b-col
        cols="12"
        md="3"
        v-for="(user, index) in user_information"
        :key="index"
      >
        <b-row v-if="user.gread == 9">
          <b-col cols="12">
            <b-link :to="`/gazzeted-form/${index}`" class="link">
              <b-card class="text-center p-3 classOne">
                <b-card-text>
                  <div
                    class="d-flex flex-row justify-content-center align-items-center"
                  >
                    <div class="me-2">
                      <img
                        src="../../../../assets/images/ict-acr-images/dashboard/formIconOne.png"
                        alt=""
                      />
                    </div>
                    <div class="text-start">
                      <p class="title m-0 p-0">গোপন আবেদন ফর্ম</p>
                      <p class="sub-title m-0 p-0">গেজেটেড অফিসার</p>
                    </div>
                  </div>
                </b-card-text>
              </b-card>
            </b-link>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="4"
        v-for="(user, index) in user_information"
        :key="index"
      >
        <b-row v-if="user.gread == 11">
          <b-col cols="12">
            <b-link
              :disabled="user.submited == 1"
              :to="`/non-gazzeted-form/${index}`"
              class="link"
            >
              <b-card class="text-center p-3 classTwo">
                <b-card-text>
                  <div
                    class="row d-flex flex-row justify-content-between align-items-center"
                  >
                    <div class="col-4">
                      <img
                        src="../../../../assets/images/ict-acr-images/dashboard/formIconOne.png"
                        alt=""
                        width="70"
                        height="70"
                      />
                    </div>
                    <div class="text-start col-8">
                      <p class="title m-0 p-0">গোপন আবেদন ফর্ম</p>
                      <p class="sub-title m-0 p-0">নন-গেজেটেড অফিসার</p>
                      <small class="m-0 p-0">
                        {{ getHumanDate(user.start_date) }}-
                        {{ getHumanDate(user.end_date) }}
                      </small>
                      <br />
                      <small class="m-0 p-0" v-if="user.submited == 1"
                        >আবেদন করা হয়েছে</small
                      >
                      <small class="m-0 p-0" v-if="user.submited == 0"
                        >আবেদন করুন</small
                      >
                    </div>
                  </div>
                </b-card-text>
              </b-card>
            </b-link>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="py-2">
      <b-col cols="12" md v-for="(summery, index) in summeryCards" :key="index">
        <b-link :to="summery.link" class="link">
          <b-card class="text-center p-3">
            <div class="text-start p-2">
              <p class="summery-title m-0 p-0">{{ summery.title }}</p>
              <p class="summery-sub-title m-0 p-0">
                {{ summery.count.toLocaleString("bn-BN") }}
              </p>
              <u class="underline">{{ summery.underline }}</u>
            </div>
          </b-card>
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { getLogedInUser } from "@/utils/auth";
import moment from "moment";
export default {
  data() {
    return {
      user: JSON.parse(getLogedInUser()),
      user_information: [
        {
          cro: null,
          designation: null,
          end_date: "",
          gread: null,
          id: null,
          iro: null,
          joining_date_current_position: "",
          start_date: "",
          submited: false,
          user_id: null,
        },
      ],
      // formCards: [
      //   {
      //     id: 1,
      //     title: "গোপন আবেদন ফর্ম",
      //     subTitle: "গেজেটেড অফিসার",
      //     cardClass: "classOne",
      //     link: "/gazzeted-form",
      //     permission: 9,
      //   },
      //   {
      //     id: 2,
      //     title: "গোপন আবেদন ফর্ম",
      //     subTitle: "নন-গেজেটেড অফিসার",
      //     cardClass: "classTwo",
      //     link: "/non-gazzeted-form",
      //     permission: 11,
      //   },
      //   {
      //     id: 3,
      //     title: "গোপন আবেদন ফর্ম",
      //     subTitle: "গেজেটেড ক্যাডার অফিসার",
      //     cardClass: "classThree",
      //     link: "/gazzeted-cadre-form",
      //     permission: 10,
      //   },
      //   {
      //     id: 4,
      //     title: "গোপন আবেদন ফর্ম",
      //     subTitle: "গোপন রিপোর্ট ফর্ম",
      //     cardClass: "classFour",
      //     link: "/report-form",
      //     permission: 100,
      //   },
      // ],
      summeryCards: [
        {
          id: 1,
          title: "মূল্যায়ন বাকি (অনুস্বাক্ষরকারী)",
          count: "0",
          underline: "তালিকা দেখুন",
          link: "/iro",
        },
        {
          id: 2,
          title: "মূল্যায়ন বাকি ( প্রতিস্বাক্ষরকারী )",
          count: "0",
          underline: "তালিকা দেখুন",
          link: "/cro",
        },
        {
          id: 3,
          title: "মোট মূল্যায়িত এ সি আর",
          count: "0",
          underline: "তালিকা দেখুন",
          link: "/acr",
        },
        // {
        //   id: 4,
        //   title: "মোট কর্মকর্তা-কর্মচারী",
        //   count: "0",
        //   underline: "তালিকা দেখুন",
        //   link: "/users",
        // },
      ],

      iro: "",
      cro: "",
      done: "",
      acrListNonGazzeted: [],
    };
  },
  created() {
    this.getReporterById();
    this.viewUserList();
    this.viewAllNonGazzetedAcr();
  },
  methods: {
    getReporterById() {
      this.user_information.forEach((e) => {
        e.start_date = new Date().toDateString();
       
      });

      this.$store
        .dispatch("reporter/getReporterById", this.user.user_id)
        .then((res) => {
          this.user_information = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getHumanDate: function (date) {
      return moment(date, "YYYY-MM-DD").locale("bn-BD").format("DD/MM/YYYY");
    },
    viewUserList() {
      this.$store
        .dispatch("userStore/viewUsers")
        .then((res) => {
          if (res.status == 200) {
            this.summeryCards[3].count = res.data.length;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    viewAllNonGazzetedAcr() {
      this.$store
        .dispatch("acrStore/viewAllNonGazzetedAcr")
        .then((res) => {
          if (res.status == 200) {
            this.acrListNonGazzeted = res.data;
            if (this.acrListNonGazzeted.length > 0) {
              this.iro = this.acrListNonGazzeted.filter(
                (element) => element.status == "iro"
              );
              this.summeryCards[0].count = this.iro.length;
              //  cro
              this.cro = this.acrListNonGazzeted.filter(
                (element) => element.status == "cro"
              );
              this.summeryCards[1].count = this.cro.length;
              // done
              this.done = this.acrListNonGazzeted.filter(
                (element) => element.status == "done"
              );
              this.summeryCards[2].count = this.done.length;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
.sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: white !important;
}
.classOne {
  background: #364165;
  color: white;
}
.classTwo {
  background: #634373;
  color: white;
}
.classThree {
  background: #3d6851;
  color: white;
}
.classFour {
  background: #333339;
  color: white;
}
a {
  text-decoration: none !important;
}
/* 2nd card section */
.summery-title {
  font-size: 19px;
}
.summery-sub-title {
  font-size: 32px;
}
.underline {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 14px;
  color: #eeac49;
}
@media only screen and (max-width: 1500px) {
  .summery-title {
    font-size: 15px;
  }
  .summery-sub-title {
    font-size: 25px;
  }
  .title {
    font-size: 10px !important;
  }
  .sub-title {
    font-size: 13px !important;
    color: white !important;
  }
}
</style>
