<template>
  <b-row>
    <b-col cols="12" class="mt-4">
      <Breadcrumbs main="Dashboard" title="Dashboard" />
    </b-col>

    <b-col cols="12">
      <form-card />
    </b-col>
    <b-col cols="12" lg><chart /></b-col>
    <b-col cols="12" lg><percentage /></b-col>
    <b-col cols="12" class="mt-2">
      <nonGazzetedAcrList />
    </b-col>
  </b-row>
</template>

<script>
import formCard from "./default/formCard.vue";
import chart from "./default/chart.vue";
import percentage from "./default/percentage.vue";
import listTable from "./default/table.vue";
import nonGazzetedAcrList from "@/pages/acr/index.vue";
import { getLogedInUser } from "@/utils/auth";

export default {
  components: {
    formCard,
    chart,
    percentage,
    listTable,
    nonGazzetedAcrList,
  },
  data() {
    return {
      user: JSON.parse(getLogedInUser()),
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    getUser() {
      this.$store
        .dispatch("userStore/getUserById", this.user.user_id)
        .then((response) => {
          this.userinfo = response;
        });
    },
  },
};
</script>
