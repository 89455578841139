<template>
    <div>
      <b-row class="mx-1">
        <b-col>
          <h5 class="m-0 p-0 filter-title mb-2">
            ব্যবহারকারীর ব্যক্তিগত চলমান অনুরোধের বর্তমান অবস্থান
          </h5>
          <table-component type="user" :list="list" />
        </b-col>
      </b-row>
    </div>
  </template>
  
  <script>
  import tableComponent from "./table.vue";
  export default {
    components: {
      tableComponent,
    },
    props: {
      list: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
  };
  </script>
  
  <style></style>
  