<template>
  <li class="onhover-dropdown">
    <div class="notification-box">
      <svg>
        <use
          href="@/assets/svg/icon-sprite.svg#notification"
          @click="notification_open()"
        ></use>
      </svg>
      <span
        v-if="logedInUserNotification && logedInUserNotification.length > 0"
        class="badge rounded-pill badge-secondary"
      >
        <!-- {{ logedInUserNotification.length }} -->
        {{ logedInUserNotification.length }}
      </span>
      <span v-else class="badge rounded-pill badge-secondary"> 0 </span>
    </div>
    <div class="onhover-show-div notification-dropdown">
      <h6 class="f-18 mb-0 dropdown-title">Notitications</h6>

      <ul>
        <li
          v-for="(notification, index) in logedInUserNotification"
          :key="index"
          :class="[seen, unseenBg]"
          @click="seenNotificationData(notification)"
        >
          <p>
            {{ notification.message }} {{ notification.viewed }}
            <span class="font-danger">
              {{ getHumanDate(notification.createdAt) }}
            </span>
          </p>
        </li>
      </ul>
      <div class="text-center py-4">
        <a class="f-w-700" href="#">Check all</a>
      </div>
    </div>
  </li>
</template>

<script>
import { getNotification, getLogedInUser } from "../../utils/auth";
import moment from "moment";
import { io } from "socket.io-client";
export default {
  name: "Notifications",
  data() {
    return {
      // logedInUserNotification: JSON.parse(getNotification()),
      logedInUserNotification: [],
      notification: false,
      getLogedInUser: JSON.parse(getLogedInUser()),
      seen: "b-l-success border-4",
      unseenBg: "bg-white text-black",
      socket: null,
      // shared worker
      broadcastChannel: "",
      worker: "",
    };
  },

  mounted() {
    try {
      // shared worker
      this.worker = new SharedWorker("sworker.js");
      const id = uuid.v4();

      // Set initial web socket state to connecting. We'll modify this based
      // on events.
      let webSocketState = WebSocket.CONNECTING;

      this.worker.port.onmessage = (event) => {
        switch (event.data.type) {
          case "WSState":
            webSocketState = event.data.state;
            break;
          case "message":
            handleMessageFromPort(event.data);
            break;
        }
      };
      // Set up the broadcast channel to listen to web socket events.
      // This is also similar to above handler. But the handler here is
      // for events being broadcasted to all the tabs.
      this.broadcastChannel = new BroadcastChannel("WebSocketChannel");

      this.broadcastChannel.addEventListener("message", (event) => {
        // this.logedInUserNotification = event.data.data;

        if (event.data == "logout") {
          this.$router.push("/login");
        }

        switch (event.data.type) {
          case "WSState":
            webSocketState = event.data.state;
            break;
          case "message":
            //handleBroadcast(event.data);
            // console.log("Message paic", event.data.data);
            break;

          case "notification":
            this.getNotificationData();

            break;
        }
      });

      //Listen to broadcasts from server
      function handleBroadcast(data) {
        // console.log("This message is meant for everyone!");
        // console.log(data);
      }

      //Handle event only meant for this tab
      function handleMessageFromPort(data) {
        // console.log(`This message is meant only for user with id: ${id}`);
        // console.log(data);
      }

      //Use this method to send data to the server.
      function postMessageToWSServer(input) {
        // console.log(webSocketState, "Status");
        if (webSocketState === WebSocket.CONNECTING) {
          // console.log("Still connecting to the server, try again later!");
        } else if (
          webSocketState === WebSocket.CLOSING ||
          webSocketState === WebSocket.CLOSED
        ) {
          // console.log("Connection Closed!");
        } else {
          this.worker.port.postMessage({
            // Include the sender information as a uuid to get back the response
            from: id,
            data: input,
          });
        }
      }

      // Sent a message to server after approx 2.5 sec. This will
      // give enough time to web socket connection to be created.
      //setInterval(() => postMessageToWSServer("Initial message"), 1000);
    } catch (error) {
      console.log(error);
    }
  },

  methods: {
    startWorker() {
      this.worker.port.postMessage({
        // Include the sender information as a uuid to get back the response
        type: "tata",
      });
    },

    notification_open() {
      this.getNotificationData();
      this.notification = !this.notification;
    },
    getHumanDate: function (date) {
      // return moment(date).locale("bn-BD").format("YYYY-MM-DD HH:mm:ss");
      return moment(date).locale("en-US").format("YYYY-MM-DD hh:mm A");
    },

    async getNotificationData() {
      let res = await this.$store.dispatch(
        "userStore/notification",
        this.getLogedInUser.user_id
      );

      this.logedInUserNotification = res;

      this.logedInUserNotification.forEach((e) => {
        if (e.viewed === false) {
          this.seen = "b-l-warning border-4";
          this.unseenBg = "bg-light text-black";
        }
      });
    },

    async seenNotificationData(item) {
      // console.log("check item", item);
      let res = await this.$store.dispatch(
        "userStore/notificationSeen",
        item.id
      );
    },
  },
};
</script>
