<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          responsive
          :items="list"
          :fields="fields"
          head-variant="dark"
          striped
        >
          <template #cell(name)="row">
            <p>
              {{ row.item.name }}
            </p>
          </template>
          <template #cell(userIdNo)="row">
            <p>
              {{ row.item.userIdNo }}
            </p>
          </template>
          <template #cell(designation)="row">
            <p>
              {{ row.item.designation }}
            </p>
          </template>
          <template #cell(deadline)="row">
            <p>
              {{ getHumanDateWithoutTime(row.item.acrStart) }}
              -
              {{ getHumanDateWithoutTime(row.item.acrEnd) }}
            </p>
          </template>
          <template #cell(createdAt)="row">
            <p>
              {{ getHumanDate(row.item.createdAt) }}
            </p>
          </template>
          <template #cell(action)="row">
            <div v-if="type !== 'user'">
              <div
                class="row"
                v-if="
                  loggedInUserRole.Admin === true &&
                  loggedInUserRole.IRO == true
                "
              >
                <div class="col-12">
                  <b-link
                    v-if="row.item.status === 'iro'"
                    :to="`/gazzeted-acr-evaluation/${row.item.id}`"
                    class="btn btn-sm bg-white btn-section shadow-lg text-black"
                  >
                    অনুস্বাক্ষর
                  </b-link>
                </div>
              </div>
              <div
                class="row"
                v-if="
                  loggedInUserRole.Admin == true && loggedInUserRole.CRO == true
                "
              >
                <div class="col-12">
                  <b-link
                    v-if="row.item.status === 'cro'"
                    :to="`/gazzeted-cro-evaluation/${row.item.id}`"
                    class="btn btn-sm bg-white btn-section shadow-lg text-black"
                  >
                    প্রতিস্বাক্ষর
                  </b-link>
                </div>
              </div>
              <div
                class="row"
                v-if="
                  loggedInUserRole.Admin == true && loggedInUserRole.CRO == true
                "
              >
                <div class="col-12">
                  <div v-if="row.item.status === 'done'">
                    <b-link
                      :to="`/gazzeted-acr-print/${row.item.id}`"
                      class="btn btn-sm bg-white shadow shadow-lg ms-1"
                    >
                      <i class="fa fa-print text-black" aria-hidden="true"></i>
                    </b-link>
                    <b-link
                      :to="`/gazzeted-acr-details/${row.item.id}`"
                      class="btn btn-sm bg-white shadow-lg btn-section text-black ms-1"
                    >
                      বিস্তারিত
                    </b-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-12">
                <p
                  v-if="row.item.status === 'iro'"
                  class="btn btn-sm bg-white shadow-lg text-black"
                >
                  অনুস্বাক্ষর ({{ row.item.iro }})
                </p>
                <p
                  v-if="row.item.status === 'cro'"
                  class="btn btn-sm bg-white shadow-lg text-black"
                >
                  প্রতিস্বাক্ষর ({{ row.item.iro }})
                </p>
                <p
                  v-if="row.item.status === 'done'"
                  class="btn btn-sm bg-white shadow-lg text-black"
                >
                  Dossier
                </p>
                <p
                  v-if="row.item.status === 'user'"
                  class="btn btn-sm bg-white shadow-lg text-black"
                >
                  ফেরত পাঠানো হয়েছে
                </p>
              </div>
            </div>
          </template>
        </b-table>
        <div class="text-center">
          <h5 v-if="list.length == 0" class="text-muted mt-1">
            আপনার কোন অনুস্বাক্ষর আবেদন নেই !
          </h5>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { getUserRole } from "@/utils/auth";

import moment from "moment";
export default {
  props: {
    list: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loggedInUserRole: JSON.parse(getUserRole()),
      fields: [
        {
          key: "name",
          label: "আবেদনকারী",
          sortable: true,
        },
        {
          key: "userIdNo",
          label: "আইডি",
          sortable: true,
        },
        {
          key: "designation",
          label: "পদবী",
        },
        {
          key: "deadline",
          label: "প্রতিবেদনের সময়সীমা ",
        },
        {
          key: "createdAt",
          label: "তারিখ",
        },
        {
          key: "action",
          label: this.type !== "user" ? "একশন" : "স্ট্যাটাস",
        },
      ],
    };
  },
  methods: {
    getHumanDate: function (date) {
      // return moment(date).locale("bn-BD").format("YYYY-MM-DD HH:mm:ss");
      return moment(date).locale("en-US").format("YYYY-MM-DD hh:mm A");
    },
    getHumanDateWithoutTime: function (date) {
      return moment(date, "YYYY-MM-DD").locale("en-US").format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped>
.btn-section {
  width: 90px !important;
}
</style>
