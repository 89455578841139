const Report = () => import("../../pages/report/index");
import Body from "../../components/body";
const reportRoute = {
  path: "/report",
  component: Body,
  children: [
    {
      path: "",
      name: "report-index",
      component: Report,
      meta: { requiredAuth: true },
    },
  ],
};

export default reportRoute;
