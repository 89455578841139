const options = [
  {
    id: 1,
    name: "অত্যুত্তম",
  },
  {
    id: 2,
    name: "উত্তম",
  },
  {
    id: 3,
    name: "চলতি মান",
  },
  {
    id: 4,
    name: "চলতিমানের নিম্ন",
  },
  {
    id: 5,
    name: "নিকৃষ্ট",
  },
];
const state = {
  questions: [
    {
      index: "১",
      id: 1,
      title: "ধীশক্তি ও মানসিক তৎপরতা",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "২",
      id: 2,
      title: "বিচার ক্ষমতা ও মাত্রাজ্ঞান",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "৩",
      id: 3,
      title: "উদ্যোগ ও প্রচেষ্টা ",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "৪",
      id: 4,
      title: "প্রকাশ ক্ষমতা",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "৪(ক)",
      id: 5,
      title: "লিখন",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "৪(খ)",
      id: 6,
      title: "বচন",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "৫",
      id: 7,
      title: "কাজের পরিকল্পনা, সংগঠন ও তদারক  ক্ষমতা",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "৬",
      id: 8,
      title: "কাজের মান ও পরিমান",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "৭",
      id: 9,
      title: "অধ্যবসায় ও কর্তব্যনিষ্ঠা",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "৮",
      id: 10,
      title: "অধীনস্থদিকগে পরিচালনা ও প্রশিক্ষণ দানের ক্ষমতা",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "৯",
      id: 11,
      title: "সহযোগিতা ও বিচক্ষণতা ",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "১০",
      id: 12,
      title: "সততা",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "১০(ক)",
      id: 13,
      title: "বুদ্ধিবিত্তিক",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "১০(খ)",
      id: 14,
      title: "নৈতিক",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "১১",
      id: 15,
      title: "দায়িত্বজ্ঞান",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "১১(ক)",
      id: 16,
      title: "সাধারন",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "১১(খ)",
      id: 17,
      title: "অর্থিক বিষয়ে",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "১২",
      id: 18,
      title: "ব্যাক্তিত্ব",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "১৩",
      id: 19,
      title: "আদেশ পালনে তৎপরতা ",
      comment: "",
      options: options,
      required: false,
    },
    {
      index: "১৪",
      id: 20,
      title: "",
      comment: "",
      options: options,
      status: "NTA",
      required: false,
    },
    {
      index: "১৫",
      id: 21,
      title: "",
      comment: "",
      options: options,
      status: "NTA",
      required: false,
    },
    {
      index: "১৬",
      id: 22,
      title: "",
      comment: "",
      options: options,
      status: "NTA",
      required: false,
    },
    {
      index: "১৭",
      id: 23,
      title: "",
      comment: "",
      options: options,
      status: "NTA",
      required: false,
    },
    {
      index: "১৮",
      id: 24,
      title: "সমাজকল্যাণ আগ্রহ",
      comment: "CNA",
      required: false,
      options: [
        {
          id: 1,
          name: "সমাজকল্যাণ কার্যাবলিতে আগ্রহী",
        },
        {
          id: 2,
          name: "তাহার কর্তব্যের এই দিকটিকে রুটিন মাফিক দায়িত্ব হিসাবে গণ্য করার প্রবণতা রহিয়াছে",
        },
      ],
    },
    {
        index: "১৯",
        id: 25,
        title: "অর্থনৈতিক উন্নয়নে আগ্রহ",
        comment: "CNA",
        required: false,
        options: [
          {
            id: 1,
            name: "উন্নয়ন প্রকল্প সমূহের প্রদিকল্পনা প্রণয়ন ও কার্জকরীকরণে আগ্রহী",
          },
          {
            id: 2,
            name: "তাঁহার কর্তব্যের এই দিকটিকে রুটিন মাফিক দায়িত্ব হিসাবে গণ্য করার প্রবণতা রহিয়াছে",
          },
        ],
      },
      {
        index: "২০",
        id: 26,
        title: "জনসাধারণের সহিত ব্যবহার",
        comment: "CNA",
        required: false,
        options: [
          {
            id: 1,
            name: "বিনয়ী ও সহায়ক",
          },
          {
            id: 2,
            name: "ঔদ্বত্যের প্রবণতা রহিয়াছে",
          },
        ],
      },
      {
        index: "২১",
        id: 27,
        title: "জীবনযাত্রার মান",
        comment: "CNA",
        required: false,
        options: [
          {
            id: 1,
            name: "আয়ের জ্ঞাত সামর্থের মধ্যে জীবিকা নির্বাহ করেন",
          },
          {
            id: 2,
            name: "আয়ের জ্ঞাত সামর্থের বাহিরে জীবিকা নির্বাহের রিপোর্ট রহিয়াছে",
          },
        ],
      },
      {
        index: "২২",
        id: 28,
        title: "নিরাপত্তা বিধি প্রতিপালন",
        comment: "CNA",
        required: false,
        options: [
          {
            id: 1,
            name: "যুক্তিসংগতভাবে সতর্কতামূলক ব্যবস্থা গ্রহণ করেন",
          },
          {
            id: 2,
            name: "অবহেলার প্রবণতা রহিয়াছে",
          },
        ],
      },
      {
        index: "২৩",
        id: 29,
        title: "সময়নিষ্ঠা",
        comment: "CNA",
        required: false,
        options: [
          {
            id: 1,
            name: "সময়নিষ্ঠ",
          },
          {
            id: 2,
            name: "সময়নিষ্ঠ নহেন",
          },
        ],
      },
      {
        index: "২৪",
        id: 30,
        title: "ভ্রমণ",
        comment: "CNA",
        required: false,
        options: [
          {
            id: 1,
            name: "পর্যাপ্ত ও রীতিসম্মত",
          },
          {
            id: 2,
            name: "অপর্যাপ্ত ও রীতি বহির্ভূত",
          },
        ],
      },
  ],
};

const getters = {};

const mutations = {};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
