<template>
  <div>
    <component :is="currentRole" />
  </div>
</template>

<script>
import { getLogedInUserInfo } from "@/utils/auth";
import AdminCustomerDashboard from "./admin";
import CustomerDashboard from "./admin";
import UserDashboard from "./user";
export default {
  data() {
    return {
      currentRole: "UserDashboard",
      user: JSON.parse(getLogedInUserInfo()),
    };
  },
  components: {
    AdminCustomerDashboard,
    UserDashboard,
    CustomerDashboard,
  },
  created() {
    if (
      JSON.parse(getLogedInUserInfo()).Roles[0] &&
      JSON.parse(getLogedInUserInfo()).Roles[0].name === "admin"
    ) {
      this.currentRole = "AdminCustomerDashboard";
    }
    if (
      JSON.parse(getLogedInUserInfo()).Roles[0] &&
      JSON.parse(getLogedInUserInfo()).Roles[0].name === ""
    ) {
      this.currentRole = "UserDashboard";
    }
  },
};
</script>

<style></style>
