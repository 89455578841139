const approveList = () => import("../../pages/approve-list/index");
import Body from "../../components/body";
const approveRoute = {
  path: "/approve-list",
  component: Body,
  children: [
    {
      path: "",
      name: "index",
      component: approveList,
      meta: { requiredAuth: true },
    },
  ],
};

export default approveRoute;
